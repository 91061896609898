import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  isLogged = true;
  getSelectedWorkgroupUrl = `${environment.chatBaseUrl}chat/adminService/getSelectedWorkgroup`;
  constructor(private http: HttpClient) { }

  getAllProduct() {
    return this.http.get<any>(environment.mainurl+'product/all');
  }

  getUserSubscribeProduct(businessId) {
    return this.http.get<any>(environment.mainurl+'getBusinessSubScribeProducts?businessId='+businessId);
  }
  getUserSubscribeProductV1(businessId) {
    return this.http.get<any>(environment.mainurl+'getBusinessSubScribeProductsV1?businessId='+ btoa(businessId));
  }

  checkProductSubscribe(businessId) {
    return this.http.get<any>(environment.mainurl+'checkProductSubscribe?businessId='+businessId);
  }

  checkProductSubscribeV1(businessId) {
    return this.http.get<any>(environment.mainurl+'checkProductSubscribeV1?businessId='+ btoa(businessId));
  }

  startProvisioning(req) {
    return this.http.post<any>(environment.mainurl + 'startProvisioning', req);
  }

  getProvisioningStatus(req) {
    return this.http.post<any>(environment.mainurl + 'getProvisioningStatus', req);
  }

  getAllProductList() {
    return this.http.get<any>(environment.ticketDahsUrl + 'ticket/core/productService/findAllActive');
  }

  getAllProjectList() {
    return this.http.get<any>(environment.ticketDahsUrl + 'ticket/core/category/findAllActive');
  }

  // createTicket(req) {
  //   return this.http.post<any>(`${environment.ticketDahsUrl}ticket/core/ticket/create/support`, req);
  // }


  createTicket(req) {
    return this.http.post<any>(environment.chatBaseUrl+'nsg/NorthStar/Operations/exceptionLogger/createTicket', req);
  }
  
  getAllPriority() {
    return this.http.get<any>(`${environment.ticketDahsUrl}ticket/core/priority/findAllActive`);
  }

  getSupportToken(req) {
    return this.http.post<any>(`${environment.mainurl}support/getSupporToken`, req);
  }

  submitFeedback(req) {
    return this.http.post<any>(`${environment.mainurl}feedBack/submeetFeedBack`, req);
  }

  unsubscribe(req){
    return this.http.post<any>(environment.mainurl + 'unSubscribeBusinessproducts', req);
  }

  public getTokenData(data,token){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.http.post(environment.loginUser, data, httpOptions);
  }

  getSelectedWorkgroup(userid) {
    const uid = { userId: userid };
    return this.http.post(this.getSelectedWorkgroupUrl, uid);
  }

  getActiveReceivers(obj) {
    return this.http.post(`${environment.chatBaseUrl}nexusreports/nexusCockpit/getActiveReceivers`, obj);
  }

  getUserSubscribeProductOpt(businessId) {
    return this.http.get<any>(environment.mainurl+'v2/getBusinessSubScribeProducts?businessId='+businessId);
  }

  getUserSubscribeProductOptV1(businessId) {
    return this.http.get<any>(environment.mainurl+'v2/getBusinessSubScribeProductsV1?businessId='+ btoa(businessId));
  }
}

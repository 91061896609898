import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class OnBoardingUserService {

  constructor(private http: HttpClient) { }

  // lumen(req, businessId) {
  //   return this.http.post<any>(environment.mainurl+'onBoardLumenUser?businessId='+ businessId, req);
  // }

  lumen(req) {
    return this.http.post<any>(environment.mainurl+'onBoardLumenUser', req);
  }

  sara(req) {
    return this.http.post<any>(environment.mainurl+'provisioningSARAUser', req);
  }

  saraPublish(req) {
    return this.http.post<any>(environment.mainurl+'publishLumenUserNlp', req);
  }

  // nexus(uData, businessId) {
  //   return this.http.post<any>(environment.mainurl+'onBoardNexusUser?businessId='+ businessId, uData);
  // }

  nexus(uData) {
    return this.http.post<any>(environment.mainurl+'onBoardNexusUser', uData);
  }
  
  Buzz(req) {
    return this.http.post<any>(environment.mainurl+'onBoardBuzzUser', req);
  }

  fieldServe(req) {
    return this.http.post<any>(environment.mainurl+'onBoardFieldServeUser', req);
  }

  bcsCommunicationDetail(req) {
    return this.http.post<any>(environment.bcsCommunicationDetail, req);
  }

  TaskEasy(req) {
    return this.http.post<any>(environment.mainurl+'onBoardTaskEasyUser', req);
  }

  Amtrack(req) {
    return this.http.post<any>(environment.mainurl+'onBoardAmtrackUser', req);
  }

  TicketEasy(req) {
    return this.http.post<any>(environment.mainurl+'onBoardTicketingUser', req);
  }

  KnowMe(req) {
    return this.http.post<any>(environment.mainurl+'onBoardKnowMeUser', req);
  }

  Appointment(req) {
    return this.http.post<any>(`${environment.mainurl}onBoardAppointmentUser`, req);
  }

  WorkOrder(req){
    return this.http.post<any>(`${environment.mainurl}onBoardWorkOrderUser`, req);
  }

  Assessment(req){
    return this.http.post<any>(`${environment.mainurl}onBoardAssessmentUser`, req);
  }

  Partner(req){
    return this.http.post<any>(`${environment.mainurl}onBoardPartnerUser`, req);
  }

  fieldserveCase(req){
    return this.http.post<any>(`${environment.mainurl}onBoardPartnerUser`, req);
  }
}

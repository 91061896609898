import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

@Injectable({
  providedIn: "root",
})
export class DataExtractService {
  fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  fileExtension = ".xlsx";

  constructor(private http: HttpClient) {}

  getExportData(req) {
    return this.http.post<any>(
      environment.chatBaseUrl + "nsdataexport/metadata/getExportData",
      req
    );
  }

  getStandardReportList() {
    return this.http.get<any>(
      environment.chatBaseUrl + "nsdataexport/metadata/getStandardReportList"
    );
  }
  getStandardReportDropdownList(listName) {
    return this.http.get<any>(
      environment.chatBaseUrl +
        "nsdataexport/metadata/getFilterColumns/" +
        listName
    );
  }

  checkReportNameExist(req) {
    return this.http.post<any>(
      environment.chatBaseUrl +
        "dataexportlumen/export/checkIfReportNameExists",
      req
    );
  }
  saveGenerateReport(req) {
    return this.http.post<any>(
      environment.chatBaseUrl + "dataexportlumen/export/save",
      req
    );
  }
  getMyReportsList(requestJson) {
    return this.http.post<any>(
      environment.chatBaseUrl + "dataexportlumen/export/fetchByUserId",
      requestJson
    );
  }
  getFileDataStream(req) {
    return this.http.post(
      environment.chatBaseUrl + "dataexportlumen/export/getFileDataStream",
      req
    );
  }

  saveExportData(req) {
    return this.http.post<any>(
      environment.chatBaseUrl + "nsdataexport/metadata/saveExportData",
      req
    );
  }
  deleteFile(req) {
    return this.http.post<any>(
      environment.chatBaseUrl + "dataexportlumen/export/deleteExportFile",
      req
    );
  }

  deleteMyReportById(requestJson) {
    return this.http.post<any>(
      environment.chatBaseUrl + "dataexportlumen/export/delete",
      requestJson
    );
  }

  addReportData(req, url) {
    return this.http.post<any>(url, req);
  }
  public exportExcel(jsonData: any[], fileName: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    const wb: XLSX.WorkBook = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer: any = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    });
    this.saveExcelFile(excelBuffer, fileName);
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.fileType });
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }
}

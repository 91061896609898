import {BrowserModule} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { FooterComponent } from './shared/footer/footer.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { OtpVerificationComponent } from './otp-verification/otp-verification.component';
import { MessageService } from 'primeng/api';
import { BaseService } from './services/base.service';
import { HttpIntercepter } from './http.intercepter';
import { NgxUiLoaderModule,
  NgxUiLoaderConfig,
  SPINNER,
  POSITION,
  PB_DIRECTION,
  NgxUiLoaderRouterModule,
  NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { LocationStrategy, HashLocationStrategy, TitleCasePipe } from '@angular/common';
import { StickynavComponent } from './shared/stickynav/stickynav.component';
import { DataService } from './services/data.service';
import { NumberPipePipe } from './number-pipe.pipe';
import { DatePipe } from '@angular/common';
import { ConfirmationService } from 'primeng/api';
import { DigitonlyDirective } from './shared/directives/digitonly.directive';
import { AutofocusDirective } from './shared/auto-focus.directive';
import { HomeV2Component } from './home-v2/home-v2.component';
import {ButtonModule} from 'primeng/button';
import { SharedHeaderModule } from './shared/shared.module';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AuthGuard } from './auth.guard';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { ConfigService } from './services/config.service';
import { DataexportComponent } from './dataexport/dataexport.component';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { TooltipModule } from 'primeng/tooltip';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { NgOtpInputModule } from 'ng-otp-input';
// import { ExceptionLoggerComponent } from './exception-logger/exception-logger.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ApprovalComponent } from './approval/approval.component';
import { RejectionComponent } from './rejection/rejection.component'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

const appConfig = (config: ConfigService) => () => config.loadbaseUrl();

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#4261D3',
  bgsPosition: POSITION.centerCenter,
  bgsSize: 65,
  bgsOpacity: 0.9,
  bgsType: "ball-spin-clockwise",
  //bgsType: SPINNER.rectangleBounce, // background spinner type
  fgsType: SPINNER.fadingCircle, // foreground spinner type
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 5, // progress bar thickness
  text: '',
  textColor: '#FFFFFF',
  textPosition: 'center-center',
};

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    OtpVerificationComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    StickynavComponent,
    NumberPipePipe,
    DigitonlyDirective,
    AutofocusDirective,
    HomeV2Component,
    DataexportComponent,
    ApprovalComponent,
    RejectionComponent,
    // ExceptionLoggerComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    SharedHeaderModule,
    HttpClientModule,
    TabsModule.forRoot(),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderRouterModule.forRoot({
      exclude: ['/configuration']
    }),
    BsDropdownModule.forRoot(),
    TypeaheadModule.forRoot(),
    AppRoutingModule,
    DialogModule,
    ButtonModule,
    ToastModule,
    TableModule,
    MultiSelectModule,
    TooltipModule,
    BreadcrumbModule,
    NgxDaterangepickerMd.forRoot(),
    NgOtpInputModule,
    NgIdleKeepaliveModule.forRoot(),
    CardModule,
    ConfirmDialogModule
  ],
  providers: [
    DatePipe,
    MessageService,
    ConfirmationService,
    DataService,
    BaseService,
    AuthGuard,
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appConfig,
      multi: true,
      deps: [ConfigService]
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpIntercepter,
      multi: true
    },
    TitleCasePipe
  ],
  bootstrap: [AppComponent],

})
export class AppModule { }

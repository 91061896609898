import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TourComponent } from './header/tour/tour.component';
import { HelpComponent } from './header/help/help.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FilterPipe } from '../shared/filter.pipe';
import { DialogModule } from 'primeng/dialog';
import { CarouselModule } from 'primeng/carousel';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AccordionModule } from 'primeng/accordion';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { GalleriaModule } from 'primeng/galleria';
import { RatingModule,RatingConfig } from 'ngx-bootstrap/rating';
import { DropdownModule } from 'primeng/dropdown';
import { SidebarModule } from 'primeng/sidebar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CheckboxModule } from 'primeng/checkbox';
import { NsportalheaderModule } from '@nsportal/nsportalheader';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { MultiSelectModule } from 'primeng/multiselect';
import { BreadcrumbModule } from 'primeng/breadcrumb';

@NgModule({
  declarations: [
    HeaderComponent,
    TourComponent,
    HelpComponent,
    FilterPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PopoverModule.forRoot(),
    BsDropdownModule.forRoot(),
    DialogModule,
    CarouselModule,
    OverlayPanelModule,
    AccordionModule,
    ScrollPanelModule,
    ToastModule,
    ButtonModule,
    GalleriaModule,
    RatingModule,
    BreadcrumbModule,
    DropdownModule,
    SidebarModule,
    ConfirmDialogModule,
    CheckboxModule,
    NsportalheaderModule,
    TableModule,
    TooltipModule,
    MultiSelectModule
  ],
  exports: [
    HeaderComponent,
    TourComponent,
    HelpComponent,
    FilterPipe
  ],
  providers: [
    MessageService,
    DatePipe,
    FilterPipe,
    ConfirmationService,
    RatingConfig
  ]
})
export class SharedHeaderModule { }

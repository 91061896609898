import { Component, OnInit } from '@angular/core';
// import { SwUpdate } from '@angular/service-worker';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { browser } from 'protractor';
import { UserService } from 'src/app/services/user.service';
import { UserDeviceService } from 'src/app/services/user-device.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'NorthStar Framework';
  loaded = false;

  constructor() { }

  ngOnInit() {
    let isLoggedIn = localStorage.getItem('isLoggedIn');
    let url = window.location.hash;
    let inviteUrl = url.includes("home?email");
    let verifyUrl = url.includes("verify?email");
    if(inviteUrl){
      // this.clearPreviousLoggedInData();
      localStorage.clear();
    }    
    if(isLoggedIn && !inviteUrl && !verifyUrl){
      if(url==""){
        window.location.href = window.location.origin +"/#/"+"business-dashboard";
      }
      else{
        window.location.href = window.location.origin +"/"+url;
      }
     
    }
    // window.addEventListener('load', function (event) {
    //   console.log("event",event)
    //     sessionStorage.removeItem('tChecks');
    // });
    // if (sessionStorage.getItem('tChecks')) {
    //     // sessionStorage.clear();
    //     window.location.href = window.location.origin + "/#/" + "alert";
    //     // alert('Found a lock in session storage. The storage was cleared.');
    // }  
    // sessionStorage.removeItem('tChecks');
    // const broadcast = new BroadcastChannel('northstar_channel')
    // broadcast.postMessage('I am First');
    // broadcast.onmessage = (event) => {
    //   if (event.data === "I am First") {
    //     broadcast.postMessage(`Sorry! Already open`);
    //     alert("First Tab");
    //   }
    //   if (event.data === `Sorry! Already open`) {
    //     // alert("Duplicate Tab");
    //     let value = sessionStorage.getItem('tChecks');
    //     let userData = localStorage.getItem('userData');
    //     // let status = this.loggedInStatusService.getValue();
    //     if(!value){
    //       window.location.href = window.location.origin + "/#/" + "alert";
    //     }
    //     // window.open(location, '_self').close();
    //     // window.close();
    //   }
    // };
    // localStorage.openpages = Date.now();
    // var onLocalStorageEvent = function (e) {
    //   if (e.key == "openpages") {
    //     // this.loggedInStatusService.setValue("loggedIn");
    //     localStorage.page_available = Date.now();
    //     sessionStorage.setItem('tChecks', JSON.parse('1'));
    //     // sessionStorage.setItem('tabCount', '1');
    //     this.loaded = true;
    //     // this.loggedInStatusService.setValue('loggedIn');
    //   }
    //   if (e.key == "page_available") {
    //     // this.signupService.loggedIn.next(false);
    //     // let count = parseInt(sessionStorage.getItem('tabCount')); 
        
    //     let value = sessionStorage.getItem('tChecks');
    //     let userData = localStorage.getItem('userData');
    //     // let status = this.loggedInStatusService.getValue();
    //     if(!value){
    //       window.location.href = window.location.origin + "/#/" + "alert";
    //     }
    //     // this.router.navigateByUrl(window.location.origin + '/' + 'alert');
    //     // alert("One more page already open");
    //   }
    // };
    // window.addEventListener("storage", onLocalStorageEvent, false);
    // if (this.swUpdate.isEnabled) {
    //     this.swUpdate.available.subscribe(() => {
    //         if(confirm("New version available. Load New Version?")) {
    //             window.location.reload();
    //         }
    //     });
    // }
  }

  // deviceInfo:any = [];
  // clearPreviousLoggedInData(){
  //   this.userDeviceService.shareDeviceDetails.subscribe(
  //     (resp: any) => {
  //       console.log('Device Info', resp);
  //       this.deviceInfo = resp;
  //     }
  //   );
  //   const req = {
  //     userLogoutIp: this.deviceInfo?.IP,
  //     logoutBrowser: this.deviceInfo?.deviceInfo?.browser
  //     //Comment for now in prod deployment
  //   };
  //   this.userService.logoutUser(req)
  //     .subscribe((res: any) => {
  //       if (res.status.statusMsg === 'Success') {
  //         localStorage.clear();
  //       }
  //   })
  // }
}

<app-stickynav></app-stickynav>
<p-toast position="center" class="success mailSuccess" key="c" (onClose)="onReject()" [modal]="true"
    [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div style="text-align: center">
            <!-- <i class="pi pi-check" style="font-size: 3em"></i> -->
            <!-- <img class="pb-3" src="assets/imgs/check.png" alt=""> -->
            <h5>{{message.summary}}</h5>
            <p style="text-align: left;">{{message.detail}}</p>
            <p style="text-align: left;" >Please click on the link sent to <b>{{signUpMail}}</b> to verify.</p>
            <p class="text-break text-left font-weight-lighter text-muted">Please check your Spam/Junk
                folder if you do not receive the confirmation message in your inbox</p>
            <img style="width: 40px;" src="assets/imgs/receive.png" alt="">
        </div>
        <div class="ui-g ui-fluid">
            <div class="ui-g-10">
            </div>
            <div class="ui-g-2">
                <button type="button" pButton (click)="messageService.clear('c');" label="Close"
                    class="ui-button-secondary"></button>
            </div>
        </div>
    </ng-template>
</p-toast>

<p-toast position="center" class="success mailSuccess1" key="d" (onClose)="onReject1()" [modal]="true"
    [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div style="text-align: center">
            <!-- <i class="pi pi-check" style="font-size: 3em"></i> -->
            <!-- <img class="pb-3" src="assets/imgs/check.png" alt=""> -->
            <h5>{{message.summary}}</h5>
            <p style="text-align: left;">{{message.detail}}</p>
            <p style="text-align: left;" >Once approved you will get a verification Email link sent to <b>{{signUpMail}}</b> to verify.</p>
            <p class="text-break text-left font-weight-lighter text-muted">Please check your Spam/Junk
                folder if you do not receive the confirmation message in your inbox</p>
            <img style="width: 40px;" src="assets/imgs/receive.png" alt="">
        </div>
        <div class="ui-g ui-fluid">
            <div class="ui-g-10">
            </div>
            <div class="ui-g-2">
                <button type="button" pButton (click)="messageService.clear('d');" label="Ok"
                    class="ui-button-secondary"></button>
            </div>
        </div>
    </ng-template>
</p-toast>

<p-toast [style]="{marginTop: '80px'}"></p-toast>

<div class="p-4" style="text-align: center;margin-top: 18%;" *ngIf="displayPage==='alreadyLoggedIn'">
    <img class="pb-3" src="assets/imgs/Group.png" alt="" style="height: 150; width:200px"><br>
    <label>You are already Logged into System. Please logout from your active session and then try to login again.</label>
</div>

<div class="homecontainer login" id="page" *ngIf="(isLoggedIn$ | async) === (false) && displayPage!='alreadyLoggedIn'" [ngClass]="{'background-img': displayPage=='login'}">
    <div>
        <header class="header">
            <!-- <img src="assets/imgs/straviso.png" alt="LOGO" class="logo"> -->
            <div class="bottom-content">
                <div>
                    <!-- <h1 class="headline pl-3 pr-3">STRAVISO</h1> -->
                    <img src="assets/imgs/straviso_logo_blue.png" alt="LOGO">
                    <!-- <p class="homeBreadcrumb mb-0"><a href="http://straviso.in/">Home</a> / Login / Sign In</p> -->
                </div>
            </div>
        </header>
    </div>
    <section class="main" *ngIf="displayPage=='login'">
        <div class="row m-0">
            <div class="left elementor-col-50">
                <div class="col-xl-11 col-lg-11 col-md-12 col-sm-12 col-12 p-0">
                    <!-- textRow -->
                    <!-- <h2 class="txt-1 pt-4 pl-1">Tagline appears here</h2>
                    <p class="txt txt-2 pt-4 pl-1">
                        Description can be displayed in this section to explain StraViso products.
                        Client/Customer testimonials can be added here to share their successful partnership with StraViso
                    </p> -->
                    <!--<span class="titlenew">
                         pt-4 -->
                    <div class="subDescription">
                        <div class="subDescription-container">
                            <h3 class="heading-title">Get in touch with us to know on how to enable these flows</h3>
                        </div>
                    </div>
                    <!-- Contact us to learn how these process flows work -->
                    <!-- </span> -->
                    <div class="row m-0 processFlow">
                        <!--  pt-lg-1 pt-md-4 pt-sm-3 -->
                        <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12 p-0 list">
                            <div class="vids-wrap">
                                <div class="text-left ">
                                    <!-- <label class="mb-0 ml-2 mt-1" style="font-size: 12px;
                                    font-weight: bold;">INFORMATION REQUEST<br>HANDLED BY BOTS</label> -->
                                    <h4 class="">INFORMATION REQUEST HANDLED BY BOTS</h4>
                                </div>
                                <div class="image">
                                    <!-- <div class="text-center">
                                        <img src="assets/imgs/people.png" alt="LOGO" class="">
                                    </div> -->
                                    <div class="image1">
                                        <img class="homeImg" src="assets/imgs/mainpage/Information-Request.png" alt="">
                                        <!-- <label class="my-0 ml-2 mr-2 subTitle"> -->
                                        <p class="firstimgdiv pr-2">
                                            The customer enters an inquiry via their preferred communications
                                            channel.
                                            The bot processes the request and returns the information to the
                                            customer.
                                        </p>
                                        <!-- </label> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12 p-0 list">
                            <div class="vids-wrap">
                                <div class="text-left ">
                                    <!-- <label class="mb-0 ml-2 mt-1" style="font-size: 12px;
                                    font-weight: bold;">AUTHENTICATION<br>PERFORMED BY BOTS</label> -->
                                    <h4 class="">AUTHENTICATION PERFORMED BY BOTS</h4>
                                </div>
                                <div class="image">
                                    <!-- <div class="text-center ">
                                        <img src="assets/imgs/robo.svg" alt="LOGO" class="">
                                    </div> -->
                                    <div class="image2">
                                        <img class="homeImg"
                                            src="assets/imgs/mainpage/Authentication-Performed-by-Bots.png" alt="">
                                        <!-- <label class="my-0 ml-2  mr-2 subTitle"> -->
                                        <p class="firstimgdiv">Bots are used to validate the authenticity of the
                                            customer.</p>
                                        <!-- </label> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12 p-0 list">
                            <div class="vids-wrap">
                                <div class="text-left ">
                                    <!-- <label class="mb-0 ml-2 mt-1" style="font-size: 12px;
                                    font-weight: bold;">SCHEDULING SERVICE<br>REQUESTS VIA BOTS</label> -->
                                    <h4 class="">SCHEDULING SERVICE REQUESTS VIA BOTS</h4>
                                </div>
                                <div class="image">
                                    <!-- <div class="text-center">
                                        <img src="assets/imgs/bot.png" alt="LOGO" class="">
                                    </div> -->
                                    <div class="image3">
                                        <img class="homeImg" src="assets/imgs/mainpage/Scheduling-Service.png" alt="">
                                        <!-- <label class="my-0 ml-2 mr-2 subTitle"> -->
                                        <p class="firstimgdiv pr-2">
                                            Customers can create and schedule a service request using bots. The bot will
                                            ask the customer about the type of service as well as available dates and
                                            times.
                                        </p>
                                        <!-- </label> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row justify-content-end knowMoreBtn"> -->
                    <input type="button" class="clickHerebtn btn btn-sm" (click)="openContactPage()"
                        value="Click here to know more">
                    <!-- </div> -->
                </div>
            </div>
            <div class="right elementor-col-50">
                <div
                    class="col-xl-8 col-lg-10 col-md-8 col-sm-10 col-12 py-5 py-sm-5 m-auto offset-sm-1 offset-md-3 offset-lg-2 offset-xl-2">
                    <div class="tab-content m-20">
                        <tabset #headTabs>
                            <tab heading="Sign in" active=true (selectTab)="onSelect($event)">
                                <div *ngIf="!createPass" class="tab-content-2">
                                    <div class="signupform-wrap">
                                        <form [formGroup]="emailverificationForm"  *ngIf="!showOtpTab" autocomplete="off">
                                            <div class="content-center pb-2">
                                                <img class="userImg pb-2" src="assets/imgs/Profile-1.png" alt="">
                                                <label class="text-muted ">Please verify your User Id to proceed</label>
                                            </div>
                                            <div class="">
                                                <label class="title text-muted m-0" for="">User Id</label>
                                                <input appAutoFocus type="text" class="form-control"  formControlName="emailId" name="emailId" autocomplete="off" list="autocompleteOff" >
                                                <!-- onkeypress="return event.charCode != 32" -->
                                            </div>
                                            <!-- <div class="form-group input-group"> -->
                                                <!-- <label class="m-0" for="">Password</label> 
                                                <input [type]="fieldTextType ? 'text' : 'password'" class="form-control"
                                                    formControlName="password" placeholder="Password" name="password"
                                                    autocomplete="off" list="autocompleteOff">-->
                                                <!-- //for password view 
                                                <div class="input-group-append">
                                                    <span class="input-group-text">
                                                        <i class="pi" [ngClass]="{
                                                          'pi-eye-slash': !fieldTextType,
                                                          'pi-eye': fieldTextType
                                                        }" (click)="toggleFieldTextType()"></i>
                                                    </span>
                                                </div>-->
                                                <!-- <small class="text-hint text-danger">Password should be at least 8 characters long with combination of one captial letter,one digit and one symbol eg.A90$asdb</small> -->
                                            <!-- </div> -->
                                            <button type="submit" [disabled]="!emailverificationForm.valid" (click)="checkUserInput(emailverificationForm.value)" 
                                            class="btn btn-submit btn-block">Proceed</button>
                                        </form>

                                        <form [formGroup]="otpVerificationForm" (ngSubmit)="verifyAndFetchOrgList(otpVerificationForm.value)" *ngIf="showOtpTab && !showPasswordField" autocomplete="off">
                                            <div class="content-center pb-2">
                                                <img class="userImg pb-2" src="assets/imgs/Password.png" alt="">
                                                <label class="text-muted ">You will get OTP via email id</label>
                                            </div>
                                            <label class="title text-muted m-0" for="">Email Id</label> 
                                            <div class="form-group mb-0">
                                                <input appAutoFocus type="text" class="form-control" placeholder="" formControlName="emailId" name="emailId" autocomplete="off" list="autocompleteOff" disabled>
                                            </div>
                                            
                                            <div class="row">
                                                <div class="col-5">
                                                    <label class="title text-muted m-0" for="">Enter OTP</label> 
                                                </div>
                                                <div class="col-7">
                                                    <label class="title text-success float-right" style="margin: 3px 0px 0px 0px;">OTP valid for 5 minutes</label> 
                                                    <!-- <label class="title text-success float-right" *ngIf="enableVerify" style="margin: 3px 0px 0px 0px;"><img src="assets/imgs/check.png" style="height: 13px;" /> OTP verified successfully</label>  -->
                                                </div>
                                            </div>
                                            <div class="form-group input-group">
                                                <!-- <ng-otp [limit]="5" [allowedCharacters]="characters" (otpOut)="setOtp($event)"></ng-otp> -->
                                                <ng-otp-input  (onInputChange)="verifyOTP($event)"  [config]="config"></ng-otp-input>
                                                <div class="mt-2" style="font-size: 13px;color: blue;">
                                                    <a class="" style="cursor:pointer;" (click)="sendOTPToLoginUser({'emailId':userId})">Resend OTP</a>
                                                </div>
                                                <!-- <small class="text-hint text-danger mt-1" *ngIf="invalidOTP">{{errMsg}}</small> -->
                                            </div>
                                            <button type="submit" [disabled]="!enableVerify" class="btn btn-submit btn-block">Proceed</button>
                                        </form>

                                        <!-- <form [formGroup]="attSignInForm" (ngSubmit)="attLogin(attSignInForm.value)" *ngIf="showOtpTab && showPasswordField" autocomplete="off">
                                            <div class="content-center pb-2">
                                                <img class="userImg pb-2" src="assets/imgs/Password.png" alt="">
                                                <label class="text-muted">Please enter your password</label>
                                            </div>
                                            <label class="title text-muted m-0" for="">User Id</label> 
                                            <div class="form-group mb-0">
                                                <input appAutoFocus type="text" class="form-control" placeholder="" formControlName="emailId" name="emailId" autocomplete="off" list="autocompleteOff" disabled>
                                            </div>
                                            <div class="input-group" >
                                                <div class="col-5 pl-0">
                                                    <label class="title text-muted m-0" for="">Password</label> 
                                                </div>
                                                <div class="col-7 pr-0">
                                                    <label class="title text-success float-right" style="margin: 3px 0px 0px 0px;"></label> 
                                                  
                                                </div>
                                                <input [type]="fieldTextType ? 'text' : 'password'" class="form-control" formControlName="password" placeholder="Password" 
                                                name="password" autocomplete="off" list="autocompleteOff" onkeypress="return event.charCode != 32">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">
                                                        <i class="pi" [ngClass]="{
                                                          'pi-eye-slash': !fieldTextType,
                                                          'pi-eye': fieldTextType
                                                        }" (click)="toggleFieldTextType()"></i>
                                                    </span>
                                                </div>
                                                <small class="text-hint text-danger mt-1" *ngIf="invalidOTP">Please enter a valid OTP.</small>
                                            </div>
                                            <button type="submit" [disabled]="!attSignInForm.valid" class="btn btn-submit btn-block mt-2 mr-0">Proceed</button>
                                        </form> -->

                                        <!-- <div class="row pt-3">
                                            <div class="col-7"> -->
                                                <!-- <p class="notRegistered mb-0">Not Registered? <span class="signUpSpan"
                                                        (click)="staticTabs.tabs[1].active = true">Sign Up</span></p> -->
                                            <!-- </div>
                                            <div class="col-5">
                                                <p class="forget-pass text-right mb-0">
                                                    <span (click)="openFPForm()" style="cursor: pointer;">Forgot
                                                        password?</span>
                                                </p>
                                            </div>
                                        </div> -->
                                        <!-- <p class="forget-pass text-right">
                                            <span (click)="displayFP = !displayFP" style="cursor: pointer;">Forget
                                                password?</span>
                                        </p> -->
                                        <div *ngIf="!showOtpTab">
                                            <div class="or-txt"><span>OR</span></div>
                                        </div>
                                        <div class="icos-wrap text-center" *ngIf="!showOtpTab">
                                            <!-- <div class="sign-icos mb-1">
                                                <a href="{{AzurLoginUrl}}">
                                                    <img class="signup-azure" src="assets/imgs/azure-signup.png"
                                                        alt="signup-azure">
                                                </a>
                                            </div> -->
                                            <div class="row my-6 log" style="justify-content: center;">
                                                <div class="icos-wrap text-center pt-0">
                                                    <div class="sign-icos mb-1">
                                                        <a href="{{AzurLoginUrl}}" >
                                                            <img class="signup-azure" src="../../../../assets/imgs/login_azure.png"
                                                                alt="signup-azure">
                                                        </a>
                                                    </div>
                                                </div>
                                                <!-- <a href="{{AzurLoginUrl}}" tooltipPosition="top"
                                                    pTooltip="Login with Azure"><img class=""
                                                        src="assets/icons/azure.png" alt="Azure"></a>
                                                <a href="{{facebookRedirectUrl}}" tooltipPosition="top"
                                                    pTooltip="Login with Facebook" ><img
                                                        src="assets/icons/facebook.png" alt="Facebook"></a>
                                                <a href="{{googleRedirectUrl}}" tooltipPosition="top"
                                                    pTooltip="Login with Google"><img class=""
                                                        src="assets/icons/gmail.png" alt="Gmail"></a>
                                                <a href="{{linkedinRedirectUrl}}" tooltipPosition="top"
                                                    pTooltip="Login with Linkedin"><img
                                                        src="assets/icons/linkdin.png" alt="Linkedin"></a> -->
                                                <!-- <img src="assets/icons/twitter.png">
                                                <img src="assets/icons/in.png"> -->
                                            </div>
                                            <!-- <div class="sign-icos mb-1">
                                                <img src="assets/imgs/lnked-signup.png" alt="signup-linkedin">
                                            </div>
                                            <div class="sign-icos mb-5">
                                                <img src="assets/imgs/office365.svg" alt="signup-office365">
                                            </div> -->
                                        </div>
                                    </div>

                                </div>
                                <div *ngIf="createPass" class="tab-content-3">
                                    <div class="createPassword-wrap">
                                        <form [formGroup]="createPasswordForm"
                                            (ngSubmit)="createPassword(createPasswordForm.value)">
                                            <div class="form-group mb-0">
                                                <!-- <p class="mb-1">Create Password</p> -->
                                                <input [type]="fieldTextTypeCreatPass ? 'text' : 'password'"
                                                    class="form-control" formControlName="password"
                                                    placeholder="Create Password" name="password"
                                                    (input)="matchpassword = true" autocomplete="off"
                                                    list="autocompleteOff">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">
                                                        <i class="pi" [ngClass]="{
                                                              'pi-eye-slash': !fieldTextTypeCreatPass,
                                                              'pi-eye': fieldTextTypeCreatPass
                                                            }" (click)="toggleFieldTextTypeCreatePass()"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <p class="text-hint" style="line-height: 1.5;">
                                                Password should be at least 8 characters long with combination of
                                                one captial letter,one digit and one symbol eg.A90$asdb.
                                            </p>
                                            <div class="form-group mb-0">
                                                <!-- <p class="mb-1">Re-Enter Password</p> -->
                                                <input [type]="fieldTextTypeCreatPass1 ? 'text' : 'password'"
                                                    class="form-control" placeholder="Re-Enter Password"
                                                    formControlName="re_password" name="re_password"
                                                    (input)="matchpassword = true" autocomplete="off"
                                                    list="autocompleteOff">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">
                                                        <i class="pi" [ngClass]="{
                                                              'pi-eye-slash': !fieldTextTypeCreatPass1,
                                                              'pi-eye': fieldTextTypeCreatPass1
                                                            }" (click)="toggleFieldTextTypeCreatePass1()"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <p *ngIf="!matchpassword" class="incorrectPassword m-0 mb-2 mt-1">Password
                                                is not
                                                Matched.
                                            </p>
                                            <button type="submit" Class="btn btn-submit btn-block"
                                                [disabled]="!createPasswordForm.valid">Create Password</button>
                                        </form>
                                    </div>
                                </div>
                            </tab>
                            <tab *ngIf="signup" heading="Sign up" (selectTab)="onSelect($event)">
                                <div class="tab-content-1">
                                    <div class="signupform-wrap">
                                        <div class="">
                                            <h4 style="color: #383838;">Sign up now to set up your
                                                account</h4>
                                            <!-- <div class="msg">* All ﬁelds are mandatory</div> -->
                                        </div>
                                        <form [formGroup]="signUpForm" (ngSubmit)="SignUp(signUpForm.value)"
                                            autocomplete="off">
                                            <div class="form-group" [ngClass]="{'disable': invitedUser}"
                                                [attr.tabindex]="invitedUser ? -1 : 0">
                                                <!-- <p class="email-label-txt mb-0">Business Name<span
                                                        class="mandetory">*</span>
                                                </p> -->
                                                <span class="mandetory">*</span>
                                                <input type="text" class="form-control" autocomplete="off"
                                                    placeholder="Business Name" list="autocompleteOff"
                                                    pTooltip="Required Field" formControlName="businessName"
                                                    maxlength="50" [attr.tabindex]="invitedUser ? -1 : 0">
                                            </div>
                                            <p *ngIf="!signUpForm.controls['businessName'].valid &&signUpForm.controls['businessName'].dirty"
                                                class="errorMsg">Please enter valid Business Name</p>
                                            <div class="form-group mt-1" [ngClass]="{'disable': invitedUser}"
                                                [attr.tabindex]="invitedUser ? -1 : 0">
                                                <!-- <p class="email-label-txt mb-0 ">Industry</p> -->
                                                <span class="wmandetory">*</span>
                                                <select id="industry" class="w-100 form-control"
                                                    pTooltip="Required Field" formControlName="industry"
                                                    [attr.tabindex]="invitedUser ? -1 : 0">
                                                    <option selected [ngValue]="undefined">Industry</option>
                                                    <option *ngFor="let in of industry">{{in.name}}</option>
                                                </select>
                                                <!-- <input type="text" class="form-control" formControlName="industry"> -->
                                            </div>
                                            <div class="form-group mt-1" [ngClass]="{'disable': invitedUser}"
                                                [attr.tabindex]="invitedUser ? -1 : 0">
                                                <!-- <p class="email-label-txt mb-0 ">Employee</p> -->
                                                <!-- <input type="text" class="form-control" formControlName="employee"> 
                                                --><span class="wmandetory">*</span>
                                                <select id="employee" class="w-100 form-control" placeholder="Employee"
                                                    formControlName="employee" [attr.tabindex]="invitedUser ? -1 : 0">
                                                    <option selected [ngValue]="undefined">Employees</option>
                                                    <option *ngFor="let e of employees">{{e}}</option>
                                                </select>
                                            </div>
                                            <div class="form-group mt-1"
                                                [attr.tabindex]="invitedUser ? -1 : 0">
                                                <!-- <p class="email-label-txt mb-0 ">Country<span class="mandetory">*</span>
                                                </p> -->
                                                <!-- <input type="text" class="" id="country" formControlName="country" [typeahead]="countries"
                                                    [typeaheadOptionsLimit]="7"
                                                    [typeaheadMinLength]="0"
                                                    (focusout)="postcodeValidator(f.zip.value, f.country.value)"> -->
                                                <span class="mandetory">*</span>
                                                <select id="countries" class="w-100 form-control" placeholder="Country"
                                                    pTooltip="Required Field" formControlName="country"
                                                    [attr.tabindex]="invitedUser ? -1 : 0" required (change)="onCountrySelect($event.target.value)">
                                                    <option [ngValue]="null" [disabled]="true" selected>Country</option>
                                                    <option *ngFor="let c of countryList">{{c.label}}</option>
                                                </select>
                                            </div>
                                            <p *ngIf="!signUpForm.controls['country'].valid&&signUpForm.controls['country'].dirty"
                                                class="errorMsg">Please Enter Valid Country</p>
                                                <div class="form-group mt-1"
                                                [attr.tabindex]="invitedUser ? -1 : 0">
                                                <span class="mandetory ml-1"></span>
                                                <select id="states" class="w-100 form-control" placeholder="State" formControlName="state"
                                                    [attr.tabindex]="invitedUser ? -1 : 0">
                                                    <option [ngValue]="null" [disabled]="true" selected>State</option>
                                                    <option *ngFor="let c of stateList">{{c.label}}</option>
                                                </select>
                                            </div>
                                            <p *ngIf="!signUpForm.controls['state'].valid&&signUpForm.controls['state'].dirty"
                                                class="errorMsg">Please Enter Valid State</p>
                                            <div class="row mt-1">
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <!-- <p class="email-label-txt mb-0 ">First Name<span
                                                                class="mandetory">*</span></p> -->
                                                        <span class="mandetory">*</span>
                                                        <input type="text" class="form-control" placeholder="First Name"
                                                            pTooltip="Required Field" formControlName="firstName"
                                                            maxlength="30" autocomplete="off" list="autocompleteOff">

                                                    </div>
                                                    <p *ngIf="!signUpForm.controls['firstName'].valid&&signUpForm.controls['firstName'].dirty"
                                                        class="errorMsg">Please enter valid First Name</p>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <!-- <p class="email-label-txt mb-0 ">Last Name<span
                                                                class="mandetory">*</span></p> -->
                                                        <span class="mandetory">*</span>
                                                        <input type="text" class="form-control" placeholder="Last Name"
                                                            pTooltip="Required Field" formControlName="lastName"
                                                            maxlength="30" autocomplete="off" list="autocompleteOff">
                                                    </div>
                                                    <p *ngIf="!signUpForm.controls['lastName'].valid&&signUpForm.controls['lastName'].dirty"
                                                        class="errorMsg">Please enter valid Last Name</p>
                                                </div>
                                            </div>

                                            <div class="form-group mt-1" [ngClass]="{'disable': invitedUser}"
                                                [attr.tabindex]="invitedUser ? -1 : 0">
                                                <!-- <p class="email-label-txt mb-0 ">Email ID<span
                                                        class="mandetory">*</span>
                                                </p> -->
                                                <span class="mandetory">*</span>
                                                <input type="text" class="form-control" formControlName="emailId"
                                                    placeholder="Email ID" email="true" pTooltip="Required Field"
                                                    pattern="[a-zA-Z0-9._%+-]+@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})|(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$"
                                                    [ngClass]="{'disable': invitedUser}" autocomplete="off"
                                                    list="autocompleteOff" [attr.tabindex]="invitedUser ? -1 : 0">

                                            </div>
                                            <p *ngIf="!signUpForm.controls['emailId'].valid&&signUpForm.controls['emailId'].dirty"
                                                class="errorMsg">Please enter Email</p>
                                            <div class="form-group mt-1">
                                                <!-- <p class="email-label-txt mb-0 ">Contact No</p> -->
                                                <span class="wmandetory">*</span>
                                                <input type="text" class="form-control" formControlName="contactNo"
                                                    placeholder="Contact Number" maxlength="10" autocomplete="off"
                                                    list="autocompleteOff">
                                            </div>
                                            <p *ngIf="!signUpForm.controls['contactNo'].valid&&signUpForm.controls['contactNo'].dirty"
                                                class="errorMsg">Please enter valid contact no</p>
                                            <div class="form-group mt-1">
                                                <!-- <p class="email-label-txt mb-0 ">Job Title</p> --><span
                                                    class="wmandetory">*</span>
                                                <input type="text" class="form-control" formControlName="jobTitle"
                                                    placeholder="Job Title" maxlength="50" autocomplete="off"
                                                    list="autocompleteOff">
                                            </div>
                                            <p *ngIf="!signUpForm.controls['jobTitle'].valid&&signUpForm.controls['jobTitle'].dirty"
                                                class="errorMsg">Please enter valid Job Title</p>
                                            <div class="form-group mt-1">
                                                <input type="checkbox" name="agreed" formControlName="agreed"
/>
                                                <label class="agree-label"> I agree to the <a class="terms-txt"
                                                        (click)="openTermsDialog()"
                                                        triggers="mouseenter:mouseleave">Terms
                                                        of
                                                        Services</a> and <a class="terms-txt"
                                                        (click)="openPrivacyPolicy()"
                                                        triggers="mouseenter:mouseleave">Privacy Policy</a></label>
                                            </div>
                                            <div class="mt-1 mb-2 refnote">
                                                <span>By registering, you agree to the
                                                    processing
                                                    of your personal data by StraViso as described in the <a
                                                        class="privacystmt terms-txt">Privacy Policy</a>.</span>
                                            </div>
                                            <button type="submit"
                                                [disabled]="!signUpForm.valid || !signUpForm.controls['agreed'].value"
                                                class="btn btn-submit btn-block mt-1">Submit</button>
                                        </form>
                                        <!-- <p class="text-mute-color pt-3">
                                                By registering, you agree to the processing of your personal data by StraViso as
                                                described in the <span class="privacy">Privacy Statement</span>.
                                            </p> -->
                                    </div>
                                    <!-- <h2 class="or-txt"><span>Or</span></h2>
                                        <div class="icos-wrap text-center pb-2">
                                            <div class="sign-icos mb-1">
                                                <img class="signup-azure" src="assets/imgs/azure-signup.png"
                                                    alt="signup-azure">
                                            </div>
            
                                        </div> -->
                                </div>
                            </tab>
                        </tabset>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="business" *ngIf="displayPage=='business'">
        <div class="container">
            <div class="card">
                <div class="card-body tab-content-2" style="height: 430px !important;">
                    <div class="signupform-wrap" *ngIf="showOtpTab">
                        <form autocomplete="off">
                            <div class="text-center pb-2">
                                <h5 class="">Please select Business org to proceed</h5>
                                <div class="img-label">
                                    <label *ngFor="let business of businessList; let i = index">
                                        <div class="cc-selector">
                                            <span class="checkmark" *ngIf="selectedBusinessId==business.businessId" >
                                                <div class="checkmark_circle"></div>
                                                <div class="checkmark_stem"></div>
                                                <div class="checkmark_kick"></div>
                                            </span>
                                            <input type="radio" 
                                                name="business" 
                                                [value]="business.businessId" 
                                                [(ngModel)]="selectedBusinessId" 
                                                (click)="selectBusiness(business)"
                                                [checked]="(i === 0)">
                                            <img src="{{business.businessImage}}">
                                        </div>
                                        <label class="title">{{business.businessId}}</label>
                                    </label>
                                </div>
                            </div>
                            <div class="login-center" *ngIf="!externalFlow && !attFlow">
                                <label class="title text-muted m-0" for="">Email Id</label> 
                                <div class="form-group mb-0">
                                    <input appAutoFocus type="text" class="form-control" placeholder="" [(ngModel)]="userId" name="emailId" autocomplete="off" list="autocompleteOff" disabled>
                                </div>
                                <label class="title text-muted m-0" for="" *ngIf="showAuthSection && externalAdProviderBtn!='azure'">Password</label> 
                                <div class="form-group input-group" *ngIf="showAuthSection && externalAdProviderBtn!='azure'">
                                    <input [type]="fieldTextType ? 'text' : 'password'" class="form-control" placeholder="Password" name="password" [(ngModel)]="password"
                                        autocomplete="off" list="autocompleteOff">
                                    <div class="input-group-append">
                                        <span class="input-group-text">
                                            <i class="pi" [ngClass]="{'pi-eye-slash': !fieldTextType,'pi-eye': fieldTextType}" (click)="toggleFieldTextType()"></i>
                                        </span>
                                    </div>
                                </div>
                                <button type="submit" *ngIf="!extLoginOnlyFlgBtn" (click)="nsSignIn()" class="btn btn-submit btn-block mt-2">Proceed</button>
                                <a class="btn btn-submit btn-block mt-2" href="{{AzurLoginUrl}}" *ngIf="extLoginOnlyFlgBtn && externalAdProviderBtn=='azure'">Proceed</a>
                                <div class="row pt-3" *ngIf="showAuthSection && externalAdProviderBtn!='azure'">
                                    <div class="col-7"></div>
                                    <div class="col-5">
                                        <p class="forget-pass text-right mb-0">
                                            <span (click)="onFPSubmit()" style="cursor: pointer;">Forgot
                                                password?</span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="login-center" *ngIf="externalFlow && !attFlow">
                                <label class="title text-muted m-0" for="">Email Id</label> 
                                <div class="form-group mb-0">
                                    <input appAutoFocus type="text" class="form-control" [(ngModel)]="userId" name="emailId" autocomplete="off" list="autocompleteOff" disabled>
                                </div>
                                <div class="row" *ngIf="showAuthSection">
                                    <div class="col-5">
                                        <label class="title text-muted m-0" for="">OTP</label> 
                                    </div>
                                    <div class="col-7">
                                        <label class="title text-success float-right" *ngIf="!enableExtVerify" style="margin: 3px 0px 0px 0px;">OTP valid for 5 minutes</label> 
                                    </div>
                                </div>
                                <div class="form-group input-group" *ngIf="showAuthSection">
                                    <ng-otp-input  (onInputChange)="setOTPForExtFlow($event)"  [config]="config"></ng-otp-input>
                                    <!-- <small class="text-hint text-danger mt-1" *ngIf="invalidExtOTP">Please enter valid OTP.</small> -->
                                    <div class="mt-2" style="font-size: 13px;color: blue;">
                                        <a class="" style="cursor:pointer;" (click)="sendOTPToLoginUser({'emailId':userId})">Send OTP</a>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-submit btn-block" (click)="nsSignIn()">Proceed</button>
                                <!-- <button type="submit" class="btn btn-submit btn-block" *ngIf="!showAuthSection" (click)="nsSignIn()">Proceed</button> -->
                            </div>  
                            
                            <div class="login-center" *ngIf="!externalFlow && attFlow">
                                <button type="submit" class="btn btn-submit btn-block" (click)="nsSignIn()">Proceed</button>
                            </div>  
                        </form>                        
                    </div>
                </div> 
            </div>
        </div>
    </section>
</div>
<!-- aggrement text -->
<ng-template #popTemplate>Here we go:
    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptas, tempore illum nesciunt maiores, consequatur
    obcaecati sit, inventore vel dolor eos magnam tenetur magni.

    <div [innerHtml]="">aschsaihuia ajbdahdh</div>
</ng-template>

<!-- <p-dialog header="Forgot Password" [(visible)]="displayFP" [modal]="true" [style]="{width: '500px', height: 'auto'}"
    [draggable]="false">
    <div class="fp-form-wrap p-5">
        <form [formGroup]="forgetPassForm" (ngSubmit)="onFPSubmit()">
            <div class="form-group">
                <label for="email">Email Id</label>
                <input type="email" class="form-control" formControlName="email">
                <small class="form-text text-hint">
                    Valid registered email id is required.
                </small> -->
                <!-- <div *ngIf="fpSubmitted && f.email.errors">
                    <small class="form-text text-danger" *ngIf="f.email.errors.required">
                        Email id is required.
                    </small>
                    <small class="form-text text-danger" *ngIf="f.email.errors.email">
                        Valid Email id is required.
                    </small>
                </div> -->
            <!-- </div>
            <p-footer>
                <button type="submit" pButton class="forgetPassword" label="Submit"
                    [disabled]="forgetPassForm.invalid"></button>
            </p-footer>
        </form>
    </div>
</p-dialog> -->

<!-- Terms of services -->
<p-dialog [(visible)]="display" [modal]="true" [responsive]="true" [style]="{minWidth: '200px'}" [draggable]="false"
    [maximizable]="false" [baseZIndex]="10000" [closable]="false" class="dialogWidth">
    <p-header class="heading" style="font-size: 22px;">Terms of Service</p-header>
    <div class="container termsContainer">
        <div class="row subheading1">
            <h5 class="mb-0">Introduction </h5>
            <p>Welcome to the StraViso site. The terms of service govern customer acquisition and use of StraViso
                Services. By accessing the content provided on the site, you agree to follow the terms of service
                concerning access to the site and content provided. If said customer registers for a free trial of
                StraViso Services the applicable provisions of terms will also govern that free trial or those free
                services. <br>Customer will accept terms by clicking a box indicating acceptance and executing an order
                form that references this. If individual accepting the terms of service on behalf of a company or other
                legal entity, he represents that he has authority to bind such entity and its affiliates to these terms.
                If the individual accepting does not have this authority, he may not accept terms to use the services.
            </p>
        </div>
        <div class="row subheading2">
            <h5 class="mb-0">Your account </h5>
            <p>You are required to create an account and specify password to use services on the site. You must be 18
                years of age and provide accurate information of yourself. If your information changes at anytime please
                update your account to reflect this. You may not share your account with anyone and keep password
                confidential. If you feel account has been compromised please contact system administrator. You
                administrator may be able to access or disable your account without our involvement. </p>
        </div>
        <div class="row subheading2">
            <h5 class="mb-0">Use of Software</h5>
            <p>Your use of software is subject to license agreement that go with the software and documents terms that
                apply. In an event that software is provided without a license you may use through terms of said
                software. It may be used for noncommercial purpose, not altered, and not redistributed.</p>
        </div>
        <div class="row subheading2">
            <h5 class="mb-0">Terms and Termination</h5>
            <p>We have the right to terminate our sites at any time without notice. For example, we may suspend or stop
                a feature and reserve right to charge fees for additional features at any time. </p>
        </div>
        <div class="row subheading2">
            <h5 class="mb-0">Your Content</h5>
            <p>You do not transfer ownership of your content simply by posting. However, by posting you grant us,
                non-exclusive right and permission to reproduce and use content.<br>We are not responsible for and do
                not endorse content posted by any other person. We may not be held liable directly or indirectly for any
                loss or damage caused to you by content posted by others.</p>
        </div>
        <div class="row subheading2">
            <h5 class="mb-0">Your Use of the sites</h5>
            <p>Do not use site to violate any laws, offensive, or interferes with site or features. We reserve the right
                to the content you access. You consent to receive electronically any communications related to the site.
                All notices from us intended for receipt by you shall be delivered when sent to email address proved to
                us. If you choose to have your personal information public in a community or through the site you do so
                at your own risk. </p>
        </div>
        <div class="row subheading2">
            <h5 class="mb-0">User Generated Content</h5>
            <p>Published content created by you using the services is to be your sole responsibility and the
                consequences are your own. You are responsible for keeping private information not public. While using
                service and find content with copyright notices you do not remove or disable protection as it is. By
                making any copyright available on service you affirm you have authorization to make content available.
            </p>
        </div>
        <div class="row subheading2">
            <h5 class="mb-0">Intellectual Property</h5>
            <p>If you believe any content on serves infringes your copyrights you can request to remove content from the
                service by sending email to <a style="color:blue;">info@straviso.com</a>.</p>
        </div>
        <div class="row subheading2">
            <h5 class="mb-0">Our Warranties and Disclaimers</h5>
            <p>We promise to make sure you enjoy our services. We do not promise other than expressly set in terms that
                StraViso nor its service providers make any specific promise about the site or reliability availability
                or ability to meet your needs we provide site “as-is”. </p>
        </div>
        <div class="row subheading2">
            <h5 class="mb-0">Communications</h5>
            <p>The service may include other communications from StraViso such as announcements via many channels. These
                shall be considered part of service and can provide opting out options to receive newsletters however
                you can not opt-out of service announcements and/or administrative communications.</p>
        </div>
        <div class="row subheading2">
            <h5 class="mb-0">Complaints</h5>
            <p>If we receive a complaint against you, we will forward it to your primary email on user account. If you
                do not respond in 10 days from date of receipt of the email, we may disclose your name for the
                complainant to take legal action against you.</p>
        </div>
        <div class="row subheading2">
            <h5 class="mb-0">Fees and Payments</h5>
            <p>Subscription will be auto renewed at end of each subscription period unless you inform us you do not wish
                to renew. At time of auto renewal, the fee will be charged to account holder payment information.</p>
        </div>
        <div class="row subheading2">
            <h5 class="mb-0">Liability for our Services</h5>
            <p>The services entities shall not be liable for any damages arising from your use of sites or any
                third-party use of the site. These exclusions include damages for loss of profits, lost data, computer
                failure or violation of rights by any third party. </p>
        </div>
        <div class="row subheading2">
            <h5 class="mb-0">Trademark</h5>
        </div>
        <div class="row" style="margin-left: 2%;">
            <p>StraViso and names of individual services and logos are trademarks of StraViso. You agree not to display
                or use in anything without prior permission. </p>
        </div>
        <div class="row subheading2">
            <h5 class="mb-0">Publicity and Logo Usage</h5>
            <p>You agree to allow StraViso to include your company’s name and/or logo in StraViso’s marketing materials,
                advertisements, sales presentations, and on StraViso’s web site, and to link to your web site as
                applicable. It is understood that you have the right to withdraw this grant for valid business reasons
                at any point of time. Nothing in this subscription agreement shall be construed to grant either party
                any right, title or interest in the name or logo of the other party beyond that granted herein.</p>
        </div>
        <div class="row subheading2">
            <h5 class="mb-0">Additional Details</h5>
            <p>We reserve the right to modify these terms at any time. By continuing to use or login to site after terms
                have changed indicate you agree to revised terms. If you do not agree you should stop using or logging
                in to site. The site may contain links to third party websites that does not mean we endorse those
                websites or any goods or services sold there. If you do not comply with these terms and we do not take
                action right away this does not mean we are ok with your side. These terms are governed by and in
                accordance with the laws of (STATE), without regard to conflict of law rule. You agree that jurisdiction
                for any dispute under these terms resides in courts located in (CITY STATE) and further agree to submit
                to personal jurisdiction of such courts for litigating purposes on said action. If it turns out
                provision in these terms is not enforceable that will not affect any other provision. </p>
        </div>
        <div class="row subheading2 bottomPadding">
            <h6>Privacy Officer contact information info@straviso.com<br>Updated on December 23, 2019</h6>
        </div>
    </div>
    <p-footer>
        <button type="button" pButton (click)="display=false" label="Close" class="ui-button-secondary"></button>
    </p-footer>
</p-dialog>

<!-- Privacy policy -->
<p-dialog [(visible)]="displayPolicy" [modal]="true" [responsive]="true" [style]="{ minWidth: '200px'}"
    [draggable]="false" [maximizable]="false" [baseZIndex]="10000" [closable]="false" class="dialogWidth">
    <p-header class="heading" style="font-size: 22px;">StraViso Privacy Policy</p-header>
    <div class="container-fluid policyDiv">
        <div class="row">
            <div class="container privacyContainer">
                <div class="row" style="margin-left: 0%;margin-top: 1%;margin-right:0%">
                    <ul>
                        <li>
                            <input type="checkbox" checked>
                            <i></i>
                            <h2>Responsible entity</h2>
                            <p>We at StraViso are committed to protecting your privacy. This policy applies to
                                information collected on our sites or through other channels. If you do not agree with
                                the data practices described in the policy, you should not use the site. We periodically
                                update and will post changes on this page and encourage you to review. If you have
                                questions regarding the privacy policy or treatment of the information you provide
                                please contact at info@straviso.com.</p>
                        </li>
                        <li>
                            <input type="checkbox" checked>
                            <i></i>
                            <h2>What personal data do we collect</h2>
                            <p>We use our own licenses to build sites people can visit to learn more about StraViso.
                                Information we collect and manage for our marketing belongs to us and is used,
                                disclosed, and protected according to this Privacy Policy. You are free to explore the
                                site without providing any personal information. When you register for subscription
                                service, we request you provide personal information about yourself for navigational
                                information.</p>
                        </li>
                        <li>
                            <input type="checkbox" checked>
                            <i></i>
                            <h2>Personal Information</h2>
                            <p>Refers to information you voluntarily disclose that identifies you including contact
                                information such as name, email address, company name, address, phone number, and other
                                information about you or your business. </p>
                        </li>
                        <li>
                            <input type="checkbox" checked>
                            <i></i>
                            <h2>Sensitive Information</h2>
                            <p>Refers to credit numbers, personal account information, social security number, passport
                                number, driver’s license number or similar personal identifiers, racial or ethnic
                                origin, physical or mental health or other employment, financial or health information.
                            </p>
                        </li>
                        <li>
                            <input type="checkbox" checked>
                            <i></i>
                            <h2>Files</h2>
                            <p>When you view content provided, we automatically collect information about your computer
                                hardware and software. This can include IP address, browser type, domain names, internet
                                service provider, files viewed on our site, operating system, access times and referring
                                website addresses. </p>
                        </li>
                        <li>
                            <input type="checkbox" checked>
                            <i></i>
                            <h2>Information collect from third party</h2>
                            <p>We may receive personal information about you from a third-party source, including
                                partners and publicly available sources such as social media site. </p>
                        </li>
                        <li>
                            <input type="checkbox" checked>
                            <i></i>
                            <h2>Children</h2>
                            <p>The site is not intended for children under 16. If you feel you have received information
                                about a child please contact us at info@straviso.com so we may delete the information.
                            </p>
                        </li>
                        <li>
                            <input type="checkbox" checked>
                            <i></i>
                            <h2>How we use information collect</h2>
                            <p>We use information collected only in compliance with the policy and customers who
                                subscribe to our subscription service are obligated to our agreements with them and
                                comply. We will not sell information to any third party. We may use personal information
                                to improve your browsing experience, send information to you that may be of interest to
                                you, promote use of services to you and share promotional information, provide other
                                companies with statistical information about our users but not identify individual user.
                                We may contact you regarding third party offerings and not transfer your personal
                                information directly to third party. </p>
                        </li>
                        <li>
                            <input type="checkbox" checked>
                            <i></i>
                            <h2>Customer Testimonials and Comments</h2>
                            <p>We post customer testimonials and comments on our sites which may contain personal
                                information with consent from customer via email prior to posting name and testimonial.
                            </p>
                        </li>
                        <li>
                            <input type="checkbox" checked>
                            <i></i>
                            <h2>Payment Information</h2>
                            <p>If you provide payment information, we will check your financial qualifications and
                                collect payment from a third-party service provider to manage processing. The provider
                                is not permitted to store or use information you provide except for sole use of
                                processing payment on our behalf. </p>
                        </li>
                        <li>
                            <input type="checkbox" checked>
                            <i></i>
                            <h2>What device and usage data we process</h2>
                            <p>When you visit the sites, we may place a cookie on your browser or device that collects
                                information, including personal data and online activity over time and across different
                                sites. Cookies allow us to track usage and improve customized your experience. Using
                                both session-based cookies exist only during one session and disappear from computer
                                when you close browser. Persistent cookies remain on computer or device after you close
                                the browser or turn off device. To change cookie settings of the site visit preferences
                                to disable, however, it may limit your use of certain features or functions on sites and
                                services. </p>
                        </li>
                        <li>
                            <input type="checkbox" checked>
                            <i></i>
                            <h2>Telephony log information</h2>
                            <p>Using features of our site on mobile device will allow collection of telephony log
                                information (phone numbers, time and date of calls, duration of calls, SMS routing
                                information and type of call). </p>
                        </li>
                        <li>
                            <input type="checkbox" checked>
                            <i></i>
                            <h2>Device event information</h2>
                            <p>Using features of our site on mobile device will allow collection of device event
                                information such as crashing information, system and hardware settings and browser
                                language. </p>
                        </li>
                        <li>
                            <input type="checkbox" checked>
                            <i></i>
                            <h2>Location information </h2>
                            <p>Using features of our site on mobile device will allow collection of location information
                                through IP address, GPS and other sensors that provide us information on nearby devices,
                                Wi-Fi access points and cell towers. </p>
                        </li>
                        <li>
                            <input type="checkbox" checked>
                            <i></i>
                            <h2>Purposes for processing personal data</h2>
                            <p>We process your personal data to perform our contract with you to use the sites and
                                services and fulfill obligations under terms of use/service. Promote security of sites
                                and services by tracking use of the site by verifying activity, investigating suspicious
                                activity and enforcing terms and policies for legitimate interest in protecting rights
                                of others. Managing registration and attendance to plan and host event personal data
                                including sending related communication to you. If you register for contest or
                                promotion, we process personal data to perform our contract and additional rules may
                                apply about how personal data will be processed. If you provide with financial
                                information we will verify for collection of payment to complete transaction and perform
                                contract. We analyze personal data to research trends and usage of interactions with
                                sites and services for interest in developing and improving sites and provide customer
                                with relevant services to offer. Personal data is used to register office visits and
                                manage non-disclosure agreements that visitors may sign. We will push marketing
                                communication to personal data about our products, promotions or events as necessary for
                                legitimate interest or to extent you provide your prior consent. </p>
                        </li>


                        <li>
                            <input type="checkbox" checked>
                            <i></i>
                            <h2>Who do we share personal data with</h2>
                            <p>We share personal data with contracted service providers and companies located in
                                countries in which we operate. If you attend site event or webinar relevant personal
                                data will be processed and list of companies currently with StraViso may be exhibited to
                                our use. </p>
                        </li>
                        <li>
                            <input type="checkbox" checked>
                            <i></i>
                            <h2>International transfer of personal data</h2>
                            <p>Your personal information may be collected and stores in the United States and/or by
                                affiliates that may be based in other countries. We ensure the recipient offers an
                                adequate level of protection for the transfer of data as approved US commissions, or we
                                will ask for your prior consent of international data transfers.</p>
                        </li>
                        <li>
                            <input type="checkbox" checked>
                            <i></i>
                            <h2>How long we keep your personal data</h2>
                            <p>We may retain personal data based on original purpose determined appropriate based on
                                amount and sensitivity and nature of your personal data processed and/or risk from
                                unauthorized disclosure of your personal data on basis of legal requirements. </p>
                        </li>
                        <li>
                            <input type="checkbox" checked>
                            <i></i>
                            <h2>How we secure your personal data</h2>
                            <p>We add safeguards to protect personal data against destruction, unauthorized disclosures,
                                access, alterations or loss of the personal data we use. You are solely responsible for
                                ultimately protection of your password and limiting access to your individual devices.
                                If you have questions please contact us by using contact section below. </p>
                        </li>
                        <li>
                            <input type="checkbox" checked>
                            <i></i>
                            <h2>Changes to the privacy statement</h2>
                            <p>We have the right to update privacy policy at any time to share any changes in our legal
                                requirements, technologies and other factors. We will update the effective date at the
                                top of this policy. We encourage users to review periodically about the collection and
                                sharing of your personal data. </p>
                        </li>
                        <li>
                            <input type="checkbox" checked>
                            <i></i>
                            <h2>Contacting us</h2>
                            <p>We want to work with you to obtain resolutions of any concern about privacy. If you have
                                question regarding privacy statement please mail us at: </p>
                            <p>StraViso<br>1 Cowboys Way<br>Frisco, TX 75034</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <p-footer>
        <button type="button" pButton (click)="displayPolicy=false" label="Close" class="ui-button-secondary"></button>
    </p-footer>
</p-dialog>

<p-dialog header="" [(visible)]="multitab" [modal]="true" [style]="{width: '600px', height: 'auto'}"
    [draggable]="false" [closeOnEscape]="false" [showHeader]="false">
    <div class="p-4" style="text-align: center;">
        <img class="pb-3" src="assets/imgs/Group.png" alt="" style="height: 150; width:200px">
        <label>You are already Logged into System. Please logout from your active session and then try to login again.</label>
        <!-- <p-button (click)="closeWindow()" label="OK"></p-button> -->
    </div>
</p-dialog>
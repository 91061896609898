import { Injectable } from '@angular/core';
import { ignoreElements } from 'rxjs/operators';
import { UamService } from './uam.service';

@Injectable({
  providedIn: 'root'
})
export class UserAccessService {

  componentServiceAccess: any = [];
  NSaccess: any;
  MyDashboardAccess: any = [];
  constructor(private homeService: UamService) {
    this.homeService.shareUserAccess.subscribe(
      (res: any) => {
        this.componentServiceAccess = res;
        this.NSaccess = this.componentServiceAccess.filter(x => x.data.moduleName === 'NS Default')[0];
        this.MyDashboardAccess = this.componentServiceAccess.filter(x => x.data.moduleName === 'MyDashBoard')[0];
      }
    );
  }

  checkInternalAccess(moduleName,tag){
    if(!localStorage.getItem('userData')){
      return true;
    }
    //if user is Super Admin, all access granted
    else if(JSON.parse(localStorage.getItem('userData')).userRole === 'Super Admin'  ||
    JSON.parse(localStorage.getItem('UAMAccess')) === false){
      return true;
    }
    //if not Super Admin, check the componentAccess
    else if(this.componentServiceAccess.length !== 0){
      if(!moduleName){
        return true;
      }
      const children = this.componentServiceAccess;
      const module = children.filter((x)=> x.data.moduleName === moduleName)[0];
      const type =  module ? module.data[tag] : {checked: false};
      return type.checked;
    }else{ // if UAM API fails, by any change just allow view option, no other option allowed.
      return tag === 'view' ? true : false;
    }

  }

  checkInternalDefaultAccess(moduleName,tag){
    if(!localStorage.getItem('userData')){
      return true;
    }
    //if user is Super Admin, all access granted
    else if(JSON.parse(localStorage.getItem('userData')).userRole === 'Super Admin' ||
    JSON.parse(localStorage.getItem('UAMAccess')) === false){
      return true;
    }
    //if not Super Admin, check the componentAccess
    else if(this.NSaccess?.length !== 0){
      if(!moduleName){
        return true;
      }
      const children = this.NSaccess?.children;
      if(children){
        const module = this.checkIfModulePresent(children,moduleName);
        const type = module?.data[tag];
        return type ? type?.checked : false;
      }else{
        return false;
      }
    }else{ // if UAM API fails, by any change just allow view option, no other option allowed.
      return tag === 'view' ? true : false;
    }

  }

  checkIfModulePresent(children,module){
    const moduleArray = children.filter((x)=> x.data.moduleName === module)[0];
    if(moduleArray){
      return moduleArray;
    }else{
      for(const item of children){
        if (item.children) {
          //for (let k = 0; k < element['children'].length; k++) {
            return this.checkIfModulePresent([...item.children],module);
          //}
        }
      }
    }
  }

  checkInternalMyDashboardAccess(cardId,tag){
    if(!localStorage.getItem('userData')){
      return true;
    }
    //if user is Super Admin, all access granted
    else if(JSON.parse(localStorage.getItem('userData')).userRole === 'Super Admin' ||
    JSON.parse(localStorage.getItem('UAMAccess')) === false){
      return true;
    }
    //if not Super Admin, check the componentAccess
    else if(this.MyDashboardAccess?.length !== 0){
      if(!cardId){
        return true;
      }
      const children = this.MyDashboardAccess?.children;
      const module = this.checkIfCardIDPresent(children,cardId);
      const type =  module ? module.data[tag] : {checked: false};
      return type.checked;
    }else{ // if UAM API fails, by any change just allow view option, no other option allowed.
      return tag === 'view' ? true : false;
    }

  }

  checkIfCardIDPresent(children,module){
    const moduleArray = children.filter((x)=> x.data.dashBoardCardId === module)[0];
    if(moduleArray){
      return moduleArray;
    }else{
      for(const item of children){
        if (item.children) {
          //for (let k = 0; k < element['children'].length; k++) {
            return this.checkIfModulePresent([...item.children],module);
          //}
        }
      }
    }
  }
}

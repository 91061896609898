<p-toast [style]="{marginTop: '80px'}"></p-toast>
<div class="container m-t-100" *ngIf="validToken">
    <div class="icon text-center">
        <span class="fa fa-lock fa-4x"></span>
    </div>
    <div class="sec-text text-center mt-2 mb-2">
        <h5>Reset Password</h5>
    </div>
    <div class="form-wrap">
        <form [formGroup]="resetPassForm" (ngSubmit)="onSubmit()" autocomplete="false">
            <div class="form-group">
                <label for="email">Email Id</label>
                <input type="email" class="form-control" formControlName="email" readonly>
                <!-- <small class="form-text hint-text">
                    Valid registered email id is required.
                </small>
                <div *ngIf="submitted && f.email.errors">
                    <small class="form-text text-danger" *ngIf="f.email.errors.required">
                        Email id is required.
                    </small>
                    <small class="form-text text-danger" *ngIf="f.email.errors.email">
                        Valid Email id is required.
                    </small>
                </div> -->
            </div>
            <div class="form-group">
                <label for="newpass">New Password <small class="text-danger">*</small></label>
                <!-- <input type="password" class="form-control" formControlName="newpassword" autocomplete="off" list="autocompleteOff"> -->
                <div class="input-group">
                    <input [type]="fieldTextType ? 'text' : 'password'" class="form-control" formControlName="newpassword" autocomplete="off" list="autocompleteOff" 
                     onkeypress="return event.charCode != 32">
                    <div class="input-group-append">
                        <span class="input-group-text">
                            <i class="pi" [ngClass]="{
                              'pi-eye-slash': !fieldTextType,
                              'pi-eye': fieldTextType
                            }" (click)="toggleFieldTextType()"></i>
                        </span>
                    </div>
                </div>
                <small class="form-text hint-text">
                    Password should be at least 8 characters long with combination of one captial letter,one digit and one symbol eg.A90$asdb
                </small>
                <div *ngIf="submitted && f.newpassword.errors">
                    <small class="form-text text-danger" *ngIf="f.newpassword.errors.required">
                        New Password is required.
                    </small>
                    <small class="form-text text-danger" *ngIf="f.newpassword.errors.minlength ">
                        Password should be at least 8 characters.
                    </small>
                    <small class="form-text text-danger" *ngIf="f.newpassword.errors.pattern ">
                        Password should be combination of at least one uppercase, one lower case, one digit and one special character.
                    </small>
                </div>
            </div>
            <div class="form-group">
                <label for="cpass">Confirm Password <small class="text-danger">*</small></label>
                <!-- <input type="password" class="form-control" formControlName="confirmpassword" autocomplete="off" list="autocompleteOff"> -->
                <div class="input-group">
                    <input [type]="fieldTextType1 ? 'text' : 'password'" class="form-control" formControlName="confirmpassword" autocomplete="off" list="autocompleteOff"
                     onkeypress="return event.charCode != 32">
                    <div class="input-group-append">
                        <span class="input-group-text">
                            <i class="pi" [ngClass]="{
                              'pi-eye-slash': !fieldTextType1,
                              'pi-eye': fieldTextType1
                            }" (click)="toggleFieldTextType1()"></i>
                        </span>
                    </div>
                </div>
                <div *ngIf="submitted && cPassError">
                    <small class="form-text text-danger">Confirm Password should match with New Password.</small>
                </div>
                <div *ngIf="submitted && f.confirmpassword.errors">
                    <small class="form-text text-danger" *ngIf="f.confirmpassword.errors.required">
                        Password is required.
                    </small>
                    <small class="form-text text-danger" *ngIf="f.confirmpassword.errors.minlength ">
                        Password should be at least 8 characters.
                    </small>
                    <small class="form-text text-danger" *ngIf="f.confirmpassword.errors.pattern ">
                        Password should be combination of at least one uppercase, one lower case, one digit and one special character.
                    </small>
                </div>
            </div>
            
            <button class="btn btn-primary mr-2" [disabled]="!resetPassForm.valid" type="submit">Reset Password</button>
            <button class="btn btn-secondary" (click)="goBack()">Cancel</button>

        </form>
    </div>
</div>

<div *ngIf="!validToken">
    <p class="m-4">This link has been expired.Click here to go to home page. <a href="" rel="noopener noreferrer" (click)="redirectToHome()">Click</a> </p>
</div>
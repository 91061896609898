import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient
  ) { }


  uploadUserProfile(req, mailId, businessId) {
    return this.http.post<any>(environment.mainurl + 'profile/addUserLogo?userEmail='+ mailId + '&businessId='+ businessId, req);
  }

  deleteProfilePicture(req, businessId) {
    return this.http.delete<any>(environment.mainurl + 'profile/deleteUserLogo?userEmail='+ req + '&businessId=' + businessId);
 }

  deleteUser(req){
    return this.http.post<any>(environment.deleteUser, req);
  }

  getBussinessData(){
    return this.http.get(environment.mainurl+'profile/getBusinessNameById');
  }

  logoutUser(req) {
    return this.http.post(`${environment.mainurl}logoutUser`, req);
  }

  getUserLoginDetailsData(req){
    return this.http.post(environment.mainurl+'profile/getUserLoginDetails',req);
  }

  // getText () {
  //   return this.http.get(`${environment.mainurl}logoutUser`)
  // }

}

import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { SignupService } from './services/signup.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private loginService: SignupService,
        private router: Router,
    ) { }

    canActivate( next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.loginService.isLoggedIn.pipe(
                take(1),map((isLoggedIn: boolean) => {
                 
                        if (!isLoggedIn) {
                            if(localStorage.getItem('userDetailsData')){
                                let userDetailsData=JSON.parse(localStorage.getItem('userDetailsData'));
                                if(userDetailsData['accountStatus']=='Deactive'){
                                    this.router.navigate(['/home']);
                                    return false;
                                }else{
                                    this.loginService.loggedIn.next(true);
                                    return true;
                                }
                                // return true;
                            }
                            if(!!localStorage.getItem('userData')){
                                // if(this.router.url === '/home'){
                                //     setTimeout(()=> this.loginService.loggedIn.next(true) , 1000)
                                // }else{
                                    this.loginService.loggedIn.next(true);
                                // }
    
                                return true;
                            }
                            else{
                                this.router.navigate(['/home']);
                                return false;
                            }
    
                        }
                        return true;

                })
      );
    }
}

import { AfterContentInit, Directive, ElementRef, Input, NgZone } from '@angular/core';

@Directive({
    selector: '[appAutoFocus]'
})
export class AutofocusDirective implements AfterContentInit {

    // @Input() public appAutoFocus: boolean;

    public constructor(private el: ElementRef,
        private ngZone: NgZone) {

    }

    public ngAfterContentInit() {
        this.ngZone.runOutsideAngular(() => {
            setTimeout(() => {

                this.el.nativeElement.focus();

            }, 500);
        });

    }

}
